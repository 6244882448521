import styled from 'styled-components';
import accountIcon from '../../images/accountIcon.svg';
import accountIconMobile from '../../images/accountIconMobile.svg';
import { FunctionComponent } from 'react';
import { themeGet } from '@primer/react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useAppDispatch } from '../../redux/hooks';
import { showMenu } from '../../redux/slices/modal.slice';

const Panel = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0px;
  background: #fff;
  position: relative;
  padding: 0px;
`;

const Title = styled('p')`
  font-size: 18px;
  width: 100px;
  font-weight: 600;
  text-align: right;
  color: ${themeGet('colors.accent.emphasis')};
`;

const Image = styled('img')`
  padding-left: 14px;
`;

export const CHANGE_MENU_WIDTH = 900;

export const AccountMenu: FunctionComponent = () => {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  return (
    <>
      <Panel onPointerOver={() => dispatch(showMenu())}>
        {width > CHANGE_MENU_WIDTH ? (
          <>
            <Title>Account</Title>
            <Image src={accountIcon} style={{ maxHeight: '45px' }} />
          </>
        ) : (
          <Image src={accountIconMobile} style={{ maxHeight: width > 360 ? '30px' : '20px' }} />
        )}
      </Panel>
    </>
  );
};

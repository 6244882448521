import styled from 'styled-components';

export const AddOnDescription = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  min-height: 110px;
  padding-top: 20px;
`;

export const AddOnTitle = styled('div')`
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  padding: 30px 30px 25px 30px;
  border-radius: 30px 30px 0px 0px;
  background: #896dff;
`;

export const AddOnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: auto;
  max-width: 414px;
  flex: 1 0;
`;

export const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const AddOnPrice = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 90px;
`;

export const AddOnPriceComplement = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  height: 90px;
  padding-top: 40px;
  padding-left: 10px;
`;

export const AddOnWhatsIncluded = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  padding-top: 40px;
`;

export const AddOnSpeechIcon = styled('img')`
  padding-top: 30px;
  height: 70px;
  width: 60px;
`;

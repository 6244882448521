import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ActionMenu, Button, ActionList } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useCheckoutMutation } from '../../redux/apis/payment';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { TriangleDownIcon } from '@primer/octicons-react';
import { useAppSelector } from '../../redux/hooks';
import styled from 'styled-components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { buttonStyle, colors } from '../../theme/theme';
import { REGISTRATION_PATH } from '../../utils/constants';
import { stopConfettiInner } from '../../components/confetti/confetti';
import speech from '../../images/speech.svg';
import { selectAuth } from '../../redux/slices/auth.slice';
import { PlansBox } from '../../components/plansAndBilling/PlansBox';
import {
  RowBox,
  AddOnContainer,
  AddOnTitle,
  AddOnPrice,
  AddOnPriceComplement,
  AddOnDescription,
  AddOnWhatsIncluded,
  AddOnSpeechIcon,
} from '../../components/plansAndBilling/AddOnContainer';
import { PlanItem } from '../../components/plansAndBilling/PlanItem';

const MOBILE_WIDTH = 950;

const MainTitle = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 30px;
`;

const Container = styled('div')<{ columnDisplay: boolean; transparentBg?: boolean }>`
  display: flex;
  flex-direction: ${({ columnDisplay: isMobile }) => (isMobile ? 'column' : 'row')};
  width: 100%;
  max-width: ${({ columnDisplay: isMobile }) => (isMobile ? '420px' : '1300px')};
  border-radius: 30px;
  background: ${({ transparentBg }) => (transparentBg ? 'none' : '#fff')};
  box-shadow: ${({ transparentBg }) =>
    transparentBg ? 'none' : '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'};
`;

export const PlansAndPricingPage: FunctionComponent = () => {
  stopConfettiInner();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { setHref } = useWindowLocation();
  const addOnRef = useRef(null);

  const [accountType, setAccountType] = useState('Teams');
  const [openAccountType, setOpenAccountType] = useState(false);
  const anchorRef = useRef(null);
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);

  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  useEffect(() => {
    if (window.location.hash === '#add-ons') {
      (addOnRef.current as any).scrollIntoView();
    }
  }, [addOnRef, window.location.hash]);

  return (
    <Layout bgWatermark>
      <MainTitle>
        <RowBox>
          Plans & Pricing for
          <div
            aria-expanded={openAccountType}
            onClick={() => setOpenAccountType(!openAccountType)}
            ref={anchorRef}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              paddingLeft: '10px',
              color: colors.real.medium,
            }}
          >
            {accountType}
            <TriangleDownIcon fill={colors.real.medium} size={30} />
          </div>
          <ActionMenu
            anchorRef={anchorRef}
            onOpenChange={setOpenAccountType}
            open={openAccountType}
          >
            <ActionMenu.Overlay align="start" width="small">
              <ActionList>
                <ActionList.Item key="Teams" onSelect={() => setAccountType('Teams')}>
                  Teams
                </ActionList.Item>
                {/* <ActionList.Item
                  key="Friends & Family"
                  onSelect={() => setAccountType('Friends & Family')}
                >
                  Friends & Family
                </ActionList.Item> */}
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </RowBox>
      </MainTitle>

      <PlansBox />

      <MainTitle id="add-ons" ref={addOnRef} style={{ color: ' #001959', marginTop: '50px' }}>
        Available Add-Ons
      </MainTitle>

      <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
        <AddOnContainer>
          <AddOnTitle>Taster add-ons</AddOnTitle>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>One-Time Games</AddOnPrice>
            <RowBox>
              <AddOnPrice style={{ fontSize: '26px', lineHeight: '40px' }}>$40</AddOnPrice>
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                /game room
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              No subscription needed. Buy games for up to 8 players in each room. Valid for 1 month.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to your Taster plan
            </AddOnWhatsIncluded>
            <PlanItem>
              <b>No subscription</b> needed
            </PlanItem>
            <PlanItem>
              Up to <b>8 players</b> in a game
            </PlanItem>
            <PlanItem>
              Choose from <b>all available editions</b>
            </PlanItem>
            <PlanItem>
              Stack this add-on up to 3 times for <b>events up to 24 players</b>
            </PlanItem>
            <Button
              disabled
              onClick={() => {
                if (isLoggedIn && !isGuest) {
                  checkout({ plan: 'ONE_GAME' });
                } else {
                  navigate(REGISTRATION_PATH);
                }
              }}
              size="large"
              sx={{
                width: '160px',
                marginLeft: 'auto',
                marginTop: '16px',
                ...buttonStyle,
                backgroundColor: colors.spark.light,
                borderColor: colors.spark.other,
                color: colors.spark.other,
              }}
              type="button"
              variant="primary"
            >
              Coming soon
            </Button>
          </div>
        </AddOnContainer>
        <AddOnContainer>
          <AddOnTitle>Pro add-ons</AddOnTitle>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>All Editions</AddOnPrice>
            <RowBox>
              <AddOnPrice style={{ fontSize: '26px', lineHeight: '40px' }}>$10</AddOnPrice>
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                /one month
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Increase your toolkit with access to all editions of Cozy Juicy Real. Valid for 1
              month.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to your Pro plan
            </AddOnWhatsIncluded>
            <PlanItem>
              Get access to <b>all editions</b> of Cozy Juicy Real
            </PlanItem>
          </div>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>Large Groups</AddOnPrice>
            <RowBox>
              <AddOnPrice style={{ fontSize: '26px', lineHeight: '40px' }}>$40</AddOnPrice>
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                /one month
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Increase player limit to 12 players in a game. Valid for 1 month.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to your Pro plan
            </AddOnWhatsIncluded>
            <PlanItem>
              Up to <b>12 players</b> in every game
            </PlanItem>
          </div>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>Large Events</AddOnPrice>
            <RowBox>
              <AddOnPrice style={{ fontSize: '26px', lineHeight: '40px' }}>$165</AddOnPrice>
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                /5 game rooms
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Event packs of 5, 10 or 20 extra games. Buy this add-on multiple times for large events. Valid for 1 month.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to your Pro plan
            </AddOnWhatsIncluded>
            <PlanItem>
              Host events up to <b>500 players</b>
            </PlanItem>
            <PlanItem>
              Add <b>additional games</b>
            </PlanItem>
            <PlanItem>
              Host up to <b>100 game rooms</b> simultaneously
            </PlanItem>
            <PlanItem>
              Access to <b>all editions</b>
            </PlanItem>
            <PlanItem>
              <b>Bulk create games</b> for events
            </PlanItem>
            <PlanItem>
              <b>Bulk copy game invite links</b> for events
            </PlanItem>
            <PlanItem>
              <b>Bulk delete games</b> after your events
            </PlanItem>
          </div>
        </AddOnContainer>
        <AddOnContainer>
          <AddOnTitle>Company & Enterprise add-ons</AddOnTitle>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>
              All-Hands Events
            </AddOnPrice>
            <RowBox>
              <AddOnPrice style={{ fontSize: '26px', lineHeight: '40px' }}>$300</AddOnPrice>
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                /20 simultaneous games /account
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Facilitate large events that require 11 - 20 game rooms open simultaneously. Valid for 1 month.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to one user account
            </AddOnWhatsIncluded>
            <PlanItem>
              Ability to host an event of <b>150+ players</b>
            </PlanItem>
            <PlanItem>
              Host up to <b>20 game rooms</b> at once
            </PlanItem>
          </div>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>
              Fully Facilitated Events
            </AddOnPrice>
            <RowBox>
              <AddOnSpeechIcon
                src={speech}
                style={{
                  fontSize: '26px',
                  lineHeight: '40px',
                  paddingTop: '5px',
                  height: '40px',
                  width: '40px',
                }}
              />
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                Contact us for pricing
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Take off all the pressure of facilitating an event and leave it to us.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>What’s included</AddOnWhatsIncluded>
            <PlanItem>
              <b>Hosted by skilled facilitators</b>
            </PlanItem>
            <PlanItem>
              <b>20 - 500 players</b> in breakout rooms
            </PlanItem>
            <PlanItem>
              <b>No preparation necessary</b>
            </PlanItem>
          </div>
          <div style={{ padding: '30px', borderTop: '1px solid #896DFF' }}>
            <AddOnPrice style={{ fontSize: '18px', lineHeight: '30px' }}>
              Custom Editions
            </AddOnPrice>
            <RowBox>
              <AddOnSpeechIcon
                src={speech}
                style={{
                  fontSize: '26px',
                  lineHeight: '40px',
                  paddingTop: '5px',
                  height: '40px',
                  width: '40px',
                }}
              />
              <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                Contact us for pricing
              </AddOnPriceComplement>
            </RowBox>
            <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
              Encompass your company values in a customized edition of Cozy Juicy Real. Added to all
              accounts.
            </AddOnDescription>
            <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
              Add to all user accounts
            </AddOnWhatsIncluded>
            <PlanItem>
              <b>Custom edition created</b> by the founders of Cozy Juicy Real
            </PlanItem>
            <PlanItem>
              Questions and game design aligned to <b>your company brand and values</b>
            </PlanItem>
            <PlanItem>
              Added to <b>all company accounts</b>
            </PlanItem>
            <PlanItem>
              Ideal for <b>onboarding new team members and team integration</b>
            </PlanItem>
          </div>
        </AddOnContainer>
      </Container>
    </Layout>
  );
};

import React, { FC } from 'react';
import { colors } from '../../theme/theme';
import { Button } from '@primer/react';
import { CSSObject } from 'styled-components';

type Variant =
  | 'juicyPrimary'
  | 'juicySecondary'
  | 'juicyTertiary'
  | 'sparkPrimary'
  | 'sparkSecondary'
  | 'sparkTertiary'
  | 'sparkDisabled'
  | 'realTertiary'
  | 'realDisabled';
type ButtonProps = {
  readonly variant?: Variant;
  [key: string]: any;
};

const styles: Record<Variant, CSSObject> = {
  juicyPrimary: {
    backgroundColor: colors.accent.emphasis,
    borderColor: colors.accent.border,
    color: '#fff',
    borderLeftWidth: '4px',
    borderBottomWidth: '4px',
  },
  juicySecondary: {
    backgroundColor: colors.real.muted,
    borderColor: colors.accent.emphasis,
    color: colors.accent.emphasis,
    borderLeftWidth: '4px',
    borderBottomWidth: '4px',
  },
  juicyTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.accent.emphasis,
    color: colors.accent.emphasis,
  },
  sparkPrimary: {
    backgroundColor: colors.spark.btn,
    borderColor: colors.spark.dark,
    color: '#fff',
    borderLeftWidth: '4px',
    borderBottomWidth: '4px',
  },
  sparkSecondary: {
    backgroundColor: colors.spark.muted,
    borderColor: colors.spark.dark,
    color: colors.spark.dark,
    borderLeftWidth: '4px',
    borderBottomWidth: '4px',
  },
  sparkTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.spark.btn,
    color: colors.spark.btn,
  },
  sparkDisabled: {
    backgroundColor: colors.spark.muted,
    borderColor: colors.spark.new,
    color: colors.spark.new,
  },
  realTertiary: {
    backgroundColor: '#fff',
    borderColor: colors.real.btn,
    color: colors.real.btn,
  },
  realDisabled: {
    backgroundColor: '#fff',
    borderColor: 'lightgray',
    color: 'lightgray',
  },
};

export const CJRButton: FC<ButtonProps> = ({ variant = 'juicyPrimary', ...props }) => {
  return (
    <Button size="large" sx={{ width: '100%', ...styles[variant] }} type="button" {...props}>
      {props.children}
    </Button>
  );
};

import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { TriangleUpIcon } from '@primer/octicons-react';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../redux/hooks';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { selectAuth } from '../../redux/slices/auth.slice';
import { useNavigate } from 'react-router-dom';
import { BILLING_PATH } from '../../utils/constants';

const ClickField = styled('div')`
  position: relative;
  color: blue;
`;

const Item = styled('button')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  color: #440d9b;
  font-size: 14px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border: 0px;
  cursor: pointer;
`;

export const AddOnsMenu: FunctionComponent<{
  readonly setClose: any;
  readonly leftPosition: number;
}> = ({ setClose, leftPosition }) => {
  const navigate = useNavigate();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const sub = useGetSubscriptionStatusQuery(undefined, { skip: !isLoggedIn || isGuest });

  return (
    <ClickField
      onClick={(e) => {
        isMobile ? setClose() : null;
      }}
      onPointerLeave={(e) => {
        !isMobile ? setClose() : null;
      }}
      style={{
        width: '250px',
        height: '250px',
        top: isMobile ? '0px' : '40px',
        position: 'absolute',
        left: leftPosition,
        padding: '0px',
        zIndex: 100,
      }}
    >
      <div
        style={{
          top: '15px',
          paddingLeft: '14px',
          width: '180px',
          position: 'absolute',
          left: '30px',
          background: '#fff',
          borderRadius: '30px',
          padding: '22px',
          boxShadow: '0px 0px 20px 0px #5555',
          zIndex: 12,
        }}
      >
        {sub.data?.find((s) => s.isSubscriptionActive && s.plan === 'PRO') ? (
          <>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#large-groups');
                setClose();
              }}
            >
              Large groups
            </Item>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#all-editions');
                setClose();
              }}
            >
              All editions
            </Item>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#large-events');
                setClose();
              }}
            >
              Large events
            </Item>
          </>
        ) : null}
        {sub.data?.find((s) => s.isSubscriptionActive && s.plan === 'COMPANY') ? (
          <>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#all-hands');
                setClose();
              }}
            >
              All-hands event
            </Item>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#facilitator');
                setClose();
              }}
            >
              Book a facilitator
            </Item>
            <Item
              onClick={() => {
                navigate(BILLING_PATH + '#custom');
                setClose();
              }}
            >
              Customized game
            </Item>
          </>
        ) : null}
      </div>
      <div
        style={{
          position: 'absolute',
          top: isMobile ? '-25px' : '-15px',
          left: '55px',
          zIndex: 12,
        }}
      >
        <TriangleUpIcon fill="white" size={50} />
      </div>
    </ClickField>
  );
};

import styled from 'styled-components';
import { FunctionComponent } from 'react';
import { Button, Heading } from '@primer/react';
import { useGetChildrenQuery, useGetUserQuery } from '../../redux/apis/user.api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { closeMenu, showContactForm } from '../../redux/slices/modal.slice';
import { TriangleUpIcon } from '@primer/octicons-react';
import profileIcon from '../../images/menu/profile.svg';
import logoutIcon from '../../images/menu/logout.svg';
import billing from '../../images/menu/billing.svg';
import games from '../../images/menu/games.svg';
import box from '../../images/menu/box.svg';
import howTo from '../../images/menu/howToIcon.svg';
import addToCart from '../../images/menu/add-to-cart.svg';
import chatBallon from '../../images/menu/chat-text.svg';
import emoji from '../../images/menu/emoji.svg';
import {
  BILLING_PATH,
  LOGIN_PATH,
  MY_GAMES_PATH,
  ON_BOARDING_PATH,
  PLANS_PATH,
  PROFILE_PATH,
  USER_MANAGEMENT_PATH,
} from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { selectAuth } from '../../redux/slices/auth.slice';
import { isMobile } from 'react-device-detect';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { CHANGE_MENU_WIDTH } from './AccountMenu';
import { buttonStyle } from '../../theme/theme';
import accountIcon from '../../images/accountIcon2.svg';
import membersIcon from '../../images/members.svg';
import { useLogout } from '../../hooks/useLogout';
import { useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { setMenu } from '../../redux/slices/menu.slice';

const ClickField = styled('div')``;

const Item = styled('button')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  color: #ff7300;
  font-size: 18px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 5px;
  background: white;
  border: 0px;
  cursor: pointer;
`;

const Icon = styled('img')`
  width: 30px;
  color: #ff7300;
  padding-right: 10px;
`;

const Image = styled('img')`
  padding-right: 14px;
  padding-top: 4px;
`;

export const BallonMenu: FunctionComponent = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const logoutGlobal = useLogout();
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);
  const getUserQuery = useGetUserQuery(undefined, { skip: !isLoggedIn || isGuest });
  const sub = useGetSubscriptionStatusQuery(undefined, { skip: !isLoggedIn || isGuest });
  const dispatch = useAppDispatch();
  const show = useAppSelector((state) => state.modal.showMenu);
  const getChildren = useGetChildrenQuery(undefined, { skip: !isLoggedIn || isGuest });

  let isBallonMenu = true;
  if (width < CHANGE_MENU_WIDTH) isBallonMenu = false;

  const logout = () => {
    logoutGlobal();
    navigate(LOGIN_PATH);
  };

  if (!show || !isLoggedIn || isGuest) {
    return null;
  }

  return (
    <>
      {!isBallonMenu ? (
        <div
          onClick={(e) => dispatch(closeMenu())}
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            background: 'rgb(0, 0, 0, 0.333)',
            zIndex: 11,
          }}
        />
      ) : null}
      <ClickField
        onClick={(e) => {
          isBallonMenu && isMobile ? dispatch(closeMenu()) : null;
        }}
        onPointerLeave={(e) => {
          isBallonMenu && !isMobile ? dispatch(closeMenu()) : null;
        }}
        style={
          isBallonMenu
            ? !isMobile
              ? {
                  width: '300px',
                  //height: '100%',
                  top: '6px',
                  position: 'absolute',
                  right: '0px',
                  padding: '0px',
                  zIndex: 13,
                }
              : {
                  width: '100%',
                  height: 'calc(100% - 65px)',
                  top: '65px',
                  position: 'absolute',
                  right: '0px',
                  padding: '0px',
                  zIndex: 13,
                }
            : {}
        }
      >
        <div
          style={
            isBallonMenu
              ? {
                  top: isMobile ? '0px' : '65px',
                  paddingLeft: '14px',
                  width: '250px',
                  position: 'absolute',
                  right: '30px',
                  background: '#fff',
                  borderRadius: '30px',
                  padding: '22px',
                  boxShadow: '0px 0px 20px 0px #5555',
                  zIndex: 12,
                }
              : {
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  padding: '25px',
                  width: '280px',
                  height: '100%',
                  background: '#fff',
                  overflow: 'hidden',
                  zIndex: 12,
                }
          }
        >
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
            {!isBallonMenu ? <Image src={accountIcon} style={{ maxHeight: '45px' }} /> : null}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Heading sx={{ fontSize: 2, textAlign: 'left', color: '#3B147A' }}>
                {getUserQuery.data?.firstName}
              </Heading>
              <Heading
                sx={{
                  fontSize: '14px',
                  lineHeight: '14px',
                  textAlign: 'left',
                  fontWeight: 100,
                  color: '#3B147A',
                  paddingBottom: '4px',
                }}
              >
                {(getUserQuery.data?.email ?? '').length > 23
                  ? getUserQuery.data?.email.slice(0, 22) + '...'
                  : getUserQuery.data?.email}
              </Heading>
            </div>
          </div>

          {width < CHANGE_MENU_WIDTH ? (
            <>
              <Item
                onClick={() => {
                  navigate(MY_GAMES_PATH);
                  dispatch(closeMenu());
                }}
              >
                <Icon src={games} />
                Games
              </Item>
            </>
          ) : null}

          {width < CHANGE_MENU_WIDTH ? (
            <Item
              onClick={() => {
                navigate(ON_BOARDING_PATH);
                dispatch(setMenu('onBoarding'));
                dispatch(closeMenu());
              }}
            >
              <Icon src={howTo} />
              How to
            </Item>
          ) : null}

          <Item
            onClick={() => {
              navigate(PROFILE_PATH);
              dispatch(closeMenu());
            }}
          >
            <Icon src={profileIcon} />
            My Profile
          </Item>

          {sub.data?.find(
            (s) =>
              s.isSubscriptionActive &&
              !s.isTemporaryAddOnSubscription &&
              !s.fromParent &&
              s.allowChildren
          ) || (getChildren.data?.children.length ?? 0) > 0 ? (
            <Item
              onClick={() => {
                navigate(USER_MANAGEMENT_PATH);
                dispatch(closeMenu());
              }}
            >
              <Icon src={membersIcon} />
              Manage members
            </Item>
          ) : null}

          <Item
            onClick={() => {
              navigate(BILLING_PATH);
              dispatch(closeMenu());
            }}
          >
            <Icon src={sub.data?.find((s) => s.plan == 'FREE') ? box : billing} />
            {sub.data?.find((s) => s.plan == 'FREE') ? 'My plan' : 'Billings & Plans'}
          </Item>

          {width < CHANGE_MENU_WIDTH ? (
            <Item
              onClick={() => {
                isLoggedIn && !isGuest
                  ? sub.data?.find(
                      (s) =>
                        s.isSubscriptionActive &&
                        !s.isTemporaryAddOnSubscription &&
                        s.plan !== 'FREE'
                    )
                    ? navigate(BILLING_PATH + '#add-ons')
                    : navigate(PLANS_PATH)
                  : navigate(PLANS_PATH);
                dispatch(closeMenu());
              }}
            >
              <Icon src={addToCart} />
              {isLoggedIn && !isGuest
                ? sub.data?.find(
                    (s) =>
                      s.isSubscriptionActive && !s.isTemporaryAddOnSubscription && s.plan !== 'FREE'
                  )
                  ? 'Add-ons'
                  : 'Upgrade'
                : 'Plans & Pricing'}
            </Item>
          ) : null}

          {width < CHANGE_MENU_WIDTH ? (
            <Item
              onClick={() => {
                dispatch(showContactForm());
                dispatch(closeMenu());
              }}
            >
              <Icon src={chatBallon} />
              Send feedback
            </Item>
          ) : null}

          {width < CHANGE_MENU_WIDTH ? (
            <Item
              onClick={() => {
                window.open(
                  'https://cozyjuicyreal.trafft.com/booking?t=s&s=cozy-juicy-real-demo-20-mins'
                );
                dispatch(closeMenu());
              }}
            >
              <Icon src={emoji} />
              Contact us
            </Item>
          ) : null}

          {isBallonMenu ? (
            <Item onClick={logout}>
              <Icon src={logoutIcon} />
              Logout
            </Item>
          ) : (
            <div style={{ position: 'absolute', bottom: '0px', width: '70%', margin: '14px' }}>
              <Button
                onClick={logout}
                size="medium"
                sx={{
                  mt: 3,
                  width: '100%',
                  ...buttonStyle,
                  borderColor: '#FF7300',
                  color: '#FF7300',
                }}
                type="button"
                variant="default"
              >
                Logout
              </Button>
            </div>
          )}
        </div>
        {isBallonMenu ? (
          <div
            style={{
              position: 'absolute',
              top: isMobile ? '-25px' : '40px',
              right: '60px',
              zIndex: 12,
            }}
          >
            <TriangleUpIcon fill="white" size={50} />
          </div>
        ) : null}
      </ClickField>
    </>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@primer/react';
import { useNavigate } from 'react-router-dom';
import { useCheckoutMutation, useGetSubscriptionStatusQuery } from '../../redux/apis/payment';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import styled from 'styled-components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { buttonStyle, colors } from '../../theme/theme';
import { REGISTRATION_PATH } from '../../utils/constants';
import { stopConfettiInner } from '../confetti/confetti';
import accountIcon3 from '../../images/accountIcon3.svg';
import speech from '../../images/speech.svg';
import { selectAuth } from '../../redux/slices/auth.slice';
import { PlanItem } from './PlanItem';
import { ConfirmDowngradeDialog } from './ConfirmDowngradeDialog';
import { ConfirmUpgradeDialog } from './ConfirmUpgradeDialog';
import { showModal } from '../../redux/slices/modal.slice';

const MOBILE_WIDTH = 950;

const Container = styled('div')<{ columnDisplay: boolean; transparentBg?: boolean }>`
  display: flex;
  flex-direction: ${({ columnDisplay: isMobile }) => (isMobile ? 'column' : 'row')};
  width: 100%;
  max-width: ${({ columnDisplay: isMobile }) => (isMobile ? '420px' : '1300px')};
  border-radius: 30px;
  background: ${({ transparentBg }) => (transparentBg ? 'none' : '#fff')};
  box-shadow: ${({ transparentBg }) =>
    transparentBg ? 'none' : '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'};
`;

const SubscriptionBox = styled('div')<{ isMobile: boolean; isLast: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 30px;
  border-right: ${({ isLast, isMobile }) => (isLast || isMobile ? '0' : '1')}px solid #896dff;
  border-bottom: ${({ isLast, isMobile }) => (isLast || !isMobile ? '0' : '1')}px solid #896dff;
`;

const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Title = styled('div')`
  color: #896dff;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
`;

const Icon = styled('img')`
  padding-left: 14px;
  height: 35px;
  width: 35px;
`;

const IconText = styled('div')`
  color: #a792ff;
  text-align: left;
  font-size: 16px;
  padding-top: 6px;
  padding-left: 7px;
  text-decoration: underline;
`;

const SpeechIcon = styled('img')`
  padding-top: 30px;
  height: 70px;
  width: 60px;
`;

const Description = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  min-height: 110px;
  padding-top: 20px;
`;

const Price = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 90px;
`;

const PriceComplement = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  height: 90px;
  padding-top: 40px;
  padding-left: 10px;
`;

const WhatsIncluded = styled('div')`
  color: #440d9b;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  padding-top: 40px;
`;

export const PlansBox: FunctionComponent = () => {
  stopConfettiInner();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { setHref } = useWindowLocation();
  const dispatch = useAppDispatch();

  const [originalPlan, setOriginalPlan] = useState<string>();
  const [changePlan, setChangePlan] = useState<string>();
  const [confirmChange, setConfirmChange] = useState(false);
  const { isLoggedIn, isGuest } = useAppSelector(selectAuth);

  const sub = useGetSubscriptionStatusQuery(undefined);
  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  const clickOnPlan = (plan: string) => {
    if (isLoggedIn && !isGuest) {
      const hasSubFromParent = sub.data?.find(
        (p) => !p.isTemporaryAddOnSubscription && p.isSubscriptionActive && p.fromParent
      );
      if (hasSubFromParent)
        return dispatch(
          showModal({
            message: 'To upgrade/downgrade your account, please contact your admin.',
            type: 'danger',
          })
        );

      const mainSubscription = sub.data?.find(
        (p) => !p.isTemporaryAddOnSubscription && p.isSubscriptionActive && !p.fromParent
      );

      setOriginalPlan(mainSubscription?.plan);
      if (!mainSubscription) {
        checkout({ plan });
      } else if (mainSubscription.plan != plan) {
        setChangePlan(plan);
        setConfirmChange(true);
      }
    } else {
      navigate(REGISTRATION_PATH + `?plan=${plan}`);
    }
  };

  return (
    <>
      <Container columnDisplay={width < MOBILE_WIDTH}>
        <SubscriptionBox isLast={false} isMobile={width < MOBILE_WIDTH}>
          <RowBox style={{ justifyContent: 'space-between' }}>
            <Title>Taster</Title>
            <RowBox>
              <Icon src={accountIcon3} />
              <IconText>1+</IconText>
            </RowBox>
          </RowBox>
          <Description>
            The base plan gives you a game each month, plus access to add-ons for you to play larger
            games with any edition of Cozy Juicy Real – whenever you like.
          </Description>
          <Price>Free</Price>
          <Button
            disabled={!!sub.data?.find((p) => p.plan == 'FREE')}
            onClick={() => {
              if (isLoggedIn && !isGuest) {
                const mainSubscription = sub.data?.find(
                  (p) => !p.isTemporaryAddOnSubscription && p.isSubscriptionActive && !p.validTo
                );
                setOriginalPlan(mainSubscription?.plan);
                if (mainSubscription) {
                  setChangePlan('FREE');
                  setConfirmChange(true);
                }
              } else {
                navigate(REGISTRATION_PATH);
              }
            }}
            size="large"
            sx={{
              width: '100%',
              ...buttonStyle,
              backgroundColor: '#896dff',
              borderColor: '#381f57',
            }}
            type="button"
            variant="primary"
          >
            {isLoggedIn && !isGuest
              ? sub.data?.find((p) => !p.isTemporaryAddOnSubscription && p.isSubscriptionActive)
                ? sub.data?.find(
                    (p) => !p.isTemporaryAddOnSubscription && p.isSubscriptionActive && p.validTo
                  )
                  ? 'Scheduled'
                  : 'Downgrade'
                : 'Current plan'
              : 'Sign up for free'}
          </Button>
          <WhatsIncluded>What's included</WhatsIncluded>
          <PlanItem>
            <b>8</b> total possible players per month
          </PlanItem>
          <PlanItem>
            <b>2 game rooms</b> a month{' '}
          </PlanItem>
          <PlanItem>
            Open <b>1 game room</b> at a time
          </PlanItem>
          <PlanItem>
            Up to <b>4 players</b> in a game room{' '}
          </PlanItem>
          <PlanItem>
            Host games for up to <b>4 players</b>{' '}
          </PlanItem>
          <PlanItem>
            <b>Taster</b> edition totaling 25 cards{' '}
          </PlanItem>
          <PlanItem>
            <b>Optional ‘One-Off Games’</b> for events up to 25 players
          </PlanItem>
        </SubscriptionBox>
        <SubscriptionBox isLast={false} isMobile={width < MOBILE_WIDTH}>
          <RowBox style={{ justifyContent: 'space-between' }}>
            <Title>Pro</Title>
            <RowBox>
              <Icon src={accountIcon3} />
              <IconText>1+</IconText>
            </RowBox>
          </RowBox>
          <Description>
            For professionals who want to facilitate Cozy Juicy Real games regularly with the option
            to host large events. Add-ons available – see below.
          </Description>
          <RowBox>
            <Price>$180</Price>
            <PriceComplement>/year</PriceComplement>
          </RowBox>
          <Button
            disabled={!!sub.data?.find((p) => p.isSubscriptionActive && p.plan == 'PRO')}
            onClick={() => clickOnPlan('PRO')}
            size="large"
            sx={{
              width: '100%',
              ...buttonStyle,
              backgroundColor: '#896dff',
              borderColor: '#381f57',
            }}
            type="button"
            variant="primary"
          >
            {isLoggedIn && !isGuest
              ? sub.data?.find((p) => p.isSubscriptionActive && p.plan == 'PRO')
                ? 'Current plan'
                : sub.data?.find((p) => p.plan == 'FREE')
                ? 'Buy Pro Plan'
                : 'Downgrade'
              : 'Buy Pro Plan'}
          </Button>
          <WhatsIncluded>What's included</WhatsIncluded>
          <PlanItem>
            <b>42</b> total possible players per month
          </PlanItem>
          <PlanItem>
            <b>6 game rooms</b> a month{' '}
          </PlanItem>
          <PlanItem>
            Open up to <b>3 rooms</b> at a time
          </PlanItem>
          <PlanItem>
            Up to <b>7 players</b> in a game room{' '}
          </PlanItem>
          <PlanItem>
            Host events for up to <b>21 players</b>{' '}
          </PlanItem>
          <PlanItem>
            <b>Team Building Level 1</b> edition
          </PlanItem>
          <PlanItem>
            <b>Team Building Level 2</b> edition
          </PlanItem>
          <PlanItem>
            <b>Agile Mindset</b> edition
          </PlanItem>
          <PlanItem>
            <b>Additional Spark cards</b>
          </PlanItem>
          <PlanItem>
            <b>Observer feature</b> to see how each team is progressing as they play
          </PlanItem>
          <PlanItem>
            <b>Access to live onboarding session</b>
          </PlanItem>
          <PlanItem>
            <b>Facilitator resources</b> (coming soon)
          </PlanItem>
          <PlanItem>
            <b>Onboarding videos</b> (coming soon)
          </PlanItem>
          <PlanItem>
            <b>Optional add-ons</b> for large groups, large events and access to all editions (see
            add-ons below)
          </PlanItem>
        </SubscriptionBox>
        <SubscriptionBox isLast={false} isMobile={width < MOBILE_WIDTH}>
          <RowBox style={{ justifyContent: 'space-between' }}>
            <Title>Company</Title>
            <RowBox>
              <Icon src={accountIcon3} />
              <IconText>4+</IconText>
            </RowBox>
          </RowBox>
          <Description>
            For organizations who want their teams to connect meaningfully in an easy and fun way.
            Add-ons available – see below.
          </Description>
          <RowBox>
            <Price>$240</Price>
            <PriceComplement>/year /account</PriceComplement>
          </RowBox>
          <Button
            disabled={!!sub.data?.find((p) => p.isSubscriptionActive && p.plan == 'COMPANY')}
            onClick={() => clickOnPlan('COMPANY')}
            size="large"
            sx={{
              width: '100%',
              ...buttonStyle,
              backgroundColor: '#896dff',
              borderColor: '#381f57',
            }}
            type="button"
            variant="primary"
          >
            {isLoggedIn && !isGuest
              ? sub.data?.find((p) => p.isSubscriptionActive && p.plan == 'COMPANY')
                ? 'Current plan'
                : 'Buy Company Plan'
              : 'Buy Company Plan'}
          </Button>
          <WhatsIncluded>What's included in each account</WhatsIncluded>
          <PlanItem>
            <b>Unlimited</b> players per month
          </PlanItem>
          <PlanItem>
            <b>Unlimited game rooms</b>
          </PlanItem>
          <PlanItem>
            Open up to <b>6 rooms</b> at a time
          </PlanItem>
          <PlanItem>
            Up to <b>10 players</b> in a game room{' '}
          </PlanItem>
          <PlanItem>
            Host events for up to <b>48 players</b>{' '}
          </PlanItem>
          <PlanItem>
            <b>Team Building Level 1</b> edition
          </PlanItem>
          <PlanItem>
            <b>Team Building Level 2</b> edition
          </PlanItem>
          <PlanItem>
            <b>Team Building Level 3</b> edition
          </PlanItem>
          <PlanItem>
            <b>Agile Mindset</b> edition
          </PlanItem>
          <PlanItem>
            <b>DEI</b> edition
          </PlanItem>
          <PlanItem>
            <b>Leadership</b> edition
          </PlanItem>
          <PlanItem>
            <b>Creativity</b> edition
          </PlanItem>
          <PlanItem>
            <b>Women's</b> edition
          </PlanItem>
          <PlanItem>
            <b>Spanish Level 1</b> edition
          </PlanItem>
          <PlanItem>
            <b>German Level 1</b> edition
          </PlanItem>
          <PlanItem>
            <b>Double Speed</b> edition
          </PlanItem>
          <PlanItem>
            <b>Additional Spark cards</b>
          </PlanItem>
          <PlanItem>
            <b>Observer feature</b> to see how each team is progressing as they play
          </PlanItem>
          <PlanItem>
            <b>Bulk create games rooms</b>
          </PlanItem>
          <PlanItem>
            <b>Bulk copy game room links</b>
          </PlanItem>
          <PlanItem>
            <b>Bulk delete game rooms</b>
          </PlanItem>
          <PlanItem>
            <b>Access to live onboarding session</b>
          </PlanItem>
          <PlanItem>
            <b>Onboarding videos</b> (coming soon)
          </PlanItem>
          <PlanItem>
            <b>Optional add-ons</b> for large groups, facilitated events and custom editions (see
            add-ons below)
          </PlanItem>
        </SubscriptionBox>
        <SubscriptionBox isLast isMobile={width < MOBILE_WIDTH}>
          <RowBox style={{ justifyContent: 'space-between' }}>
            <Title>Enterprise</Title>
            <RowBox>
              <Icon src={accountIcon3} />
              <IconText>10+</IconText>
            </RowBox>
          </RowBox>
          <Description>
            For organizations who want vulnerability and team connection to be a part of their
            culture. Fully customizable.
          </Description>
          <RowBox>
            <SpeechIcon src={speech} />
            <PriceComplement>Contact us for pricing</PriceComplement>
          </RowBox>
          <Button
            onClick={() =>
              window.open(
                'https://cozyjuicyreal.trafft.com/booking?t=s&s=cozy-juicy-real-demo-20-mins'
              )
            }
            size="large"
            sx={{
              width: '100%',
              ...buttonStyle,
              backgroundColor: colors.spark.light,
              borderColor: colors.spark.dark,
              color: colors.spark.dark,
            }}
            type="button"
            variant="primary"
          >
            Book a call
          </Button>
          <WhatsIncluded>What's included in each account</WhatsIncluded>
          <PlanItem>
            <b>Fully customizable</b> to your needs
          </PlanItem>
          <PlanItem>
            <b>All game editions</b> included
          </PlanItem>
          <PlanItem>
            <b>Private onboarding sessions</b>
          </PlanItem>
          <PlanItem>
            <b>Premium support</b>
          </PlanItem>
          <PlanItem>
            Option to include <b>fully facilitated events by our trained hosts</b>
          </PlanItem>
          <PlanItem>
            Option to include <b>customized editions tailored to your company values</b>
          </PlanItem>
        </SubscriptionBox>
      </Container>

      {(changePlan == 'FREE' && originalPlan == 'PRO') ||
      (changePlan == 'FREE' && originalPlan == 'COMPANY') ||
      (changePlan == 'PRO' && originalPlan == 'COMPANY') ? (
        <ConfirmDowngradeDialog
          changePlan={changePlan}
          confirmChange={confirmChange}
          originalPlan={originalPlan}
          setConfirmChange={setConfirmChange}
        />
      ) : null}

      {changePlan == 'COMPANY' && originalPlan == 'PRO' ? (
        <ConfirmUpgradeDialog
          changePlan={changePlan}
          confirmChange={confirmChange}
          originalPlan={originalPlan}
          setConfirmChange={setConfirmChange}
        />
      ) : null}
    </>
  );
};

import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, Heading, Tooltip } from '@primer/react';
import { Layout } from '../../components/layout/Layout';
import { useNavigate } from 'react-router-dom';
import { BackButtom } from '../../basicComponents/backButtom/BackButtom';
import { DeleteAccountButton } from '../../basicComponents/deleteAccount/DeleteAccountButton';
import { buttonStyle, colors } from '../../theme/theme';
import { KICKSTARTER_PLAN, MY_GAMES_PATH } from '../../utils/constants';
import { Bar } from '../../basicComponents/bar/Bar';
import { useListConfigsQuery, usePlanNamesQuery } from '../../redux/apis/config';
import { useGetChildrenQuery, useGetUserQuery } from '../../redux/apis/user.api';
import speech from '../../images/speech.svg';

import { useWindowLocation } from '../../hooks/useWindowLocation';
import {
  useGetSubscriptionStatusQuery,
  useCheckoutMutation,
  useReactivateSubscriptionMutation,
  SubscriptionStatus,
} from '../../redux/apis/payment';
import styled from 'styled-components';
import { PlansBox } from '../../components/plansAndBilling/PlansBox';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  AddOnContainer,
  AddOnTitle,
  AddOnPrice,
  AddOnPriceComplement,
  AddOnDescription,
  AddOnWhatsIncluded,
  AddOnSpeechIcon,
} from '../../components/plansAndBilling/AddOnContainer';
import { PlanItem } from '../../components/plansAndBilling/PlanItem';
import { useAppDispatch } from '../../redux/hooks';
import { showModal } from '../../redux/slices/modal.slice';
import { ConfirmAddOnDialog } from '../../components/plansAndBilling/ConfirmAddOnDialog';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';

const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const MOBILE_WIDTH = 950;

const MainTitle = styled('div')`
  color: #3b147a;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 30px;
`;

const Container = styled('div')<{ columnDisplay: boolean; transparentBg?: boolean }>`
  display: flex;
  flex-direction: ${({ columnDisplay: isMobile }) => (isMobile ? 'column' : 'row')};
  width: 100%;
  justify-content: center;
  max-width: ${({ columnDisplay: isMobile }) => (isMobile ? '420px' : '1300px')};
  border-radius: 30px;
  background: ${({ transparentBg }) => (transparentBg ? 'none' : '#fff')};
  box-shadow: ${({ transparentBg }) =>
    transparentBg ? 'none' : '0px 0px 20px 0px rgba(0, 0, 0, 0.25)'};
`;

export const BillingStatusPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { setHref } = useWindowLocation();
  const dispatch = useAppDispatch();
  const addOnRef = useRef(null);
  const largeGroupsRef = useRef(null);
  const largeEventsnRef = useRef(null);
  const allEditionsRef = useRef(null);
  const allHandsnRef = useRef(null);
  const customizedGamesRef = useRef(null);
  const facilitatorRef = useRef(null);

  const sub = useGetSubscriptionStatusQuery(undefined, { refetchOnMountOrArgChange: true });
  const getConfigs = useListConfigsQuery(undefined);
  const getUserQuery = useGetUserQuery(undefined);
  const [plansOverlayOpen, setPlansOverlayOpen] = useState(false);
  const [mainSub, setMainSub] = useState<SubscriptionStatus>({
    plan: 'FREE',
    isSubscriptionActive: false,
    isTemporaryAddOnSubscription: false,
    allowChildren: false,
    playedThisMonth: 0,
    maxNoOfChildren: 0,
    endOfCurrentBillingPeriod: 0,
    fromParent: false,
    paymentCard: '',
  });
  const [parentSub, setParentSub] = useState<SubscriptionStatus | undefined>(undefined);
  const getKids = useGetChildrenQuery(undefined);

  const [cancellationDate, setCancellationDate] = useState<string>('');
  const [parentCancellationDate, setParentCancellationDate] = useState<string>('');
  const [reactivateSubscription, reactivatedSubscription] = useReactivateSubscriptionMutation();
  const [monthlyDate, setMonthlyDate] = useState<Date>(new Date());
  const planNames = usePlanNamesQuery(undefined);
  const [listOfPlans, setListOfPlans] = useState<string>('');

  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  useEffect(() => {
    if (reactivatedSubscription.isSuccess) {
      dispatch(showModal({ type: 'success', message: 'Subscription reactivated!' }));
    }
  }, [reactivatedSubscription]);

  useEffect(() => {
    if (sub.isSuccess) {
      getConfigs.refetch();
      getUserQuery.refetch();
      let mainSub = sub.data.find(
        (x) => x.isSubscriptionActive && !x.isTemporaryAddOnSubscription && !x.fromParent
      );
      mainSub ??= sub.data.find((x) => x.isSubscriptionActive && x.plan == 'KICKSTARTER');
      if (mainSub) setMainSub(mainSub);

      let billingDay = 1;
      if (mainSub?.endOfCurrentBillingPeriod) {
        const endOfBilling = new Date(mainSub!.endOfCurrentBillingPeriod * 1000);
        billingDay = endOfBilling.getDate();
      }
      const now = new Date(Date.now());
      let year = now.getFullYear();
      let nextMonthAux: number;
      if (now.getDate() >= billingDay) {
        nextMonthAux = now.getMonth() + 1;
        if (nextMonthAux > 11) {
          nextMonthAux = 0;
          year++;
        }
      } else {
        nextMonthAux = now.getMonth();
      }
      setMonthlyDate(new Date(year, nextMonthAux, billingDay, 0, 0, 0, 0));

      if (mainSub?.validTo) {
        setCancellationDate(
          new Date(mainSub.validTo! * 1000).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        );
      } else {
        setCancellationDate('');
      }

      const parentSub = sub.data.find(
        (x) => x.isSubscriptionActive && !x.isTemporaryAddOnSubscription && x.fromParent
      );
      if (parentSub) setParentSub(parentSub);
      if (parentSub?.validTo) {
        setParentCancellationDate(
          new Date(parentSub.validTo! * 1000).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        );
      } else {
        setParentCancellationDate('');
      }
    }
  }, [sub]);

  useEffect(() => {
    if (sub.isSuccess && planNames.isSuccess) {
      const planNamesList = sub
        .data!.filter(
          (x) =>
            x.isSubscriptionActive || (x.plan == 'FREE' && !sub.data!.find((y) => y.fromParent))
        )
        .sort((a) => (a.isTemporaryAddOnSubscription ? 1 : -1))
        .map((p) => `${planNames.data?.[p.plan]}`);
      setListOfPlans(
        planNamesList.slice(0, -1).join(', ') +
          (planNamesList.length > 1 ? ' and ' : '') +
          planNamesList.slice(-1)
      );
    }
  }, [sub, planNames]);

  useEffect(() => {
    if (window.location.hash === '#add-ons' && addOnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#large-groups' && largeGroupsRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#large-events' && largeEventsnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#all-editions' && allEditionsRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#all-hands' && allHandsnRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#custom' && customizedGamesRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);
  useEffect(() => {
    if (window.location.hash === '#facilitator' && facilitatorRef.current)
      (addOnRef.current as any).scrollIntoView();
  }, [addOnRef.current, window.location.hash]);

  if (!sub.data || !getConfigs.data || !getUserQuery.data) return <Layout bgWatermark />;

  return (
    <Layout bgWatermark>
      <Layout.Panel style={{ width: '100%', maxWidth: '414px', position: 'relative' }}>
        <Heading as="h1" sx={{ fontSize: 3, mb: 3, textAlign: 'center' }}>
          Billing & Plans
        </Heading>
        <BackButtom goBack={() => navigate(MY_GAMES_PATH)} />
        <Box>
          Hey {getUserQuery.data?.firstName}, you’re currently on the plan(s): <b>{listOfPlans}</b>.
        </Box>

        {cancellationDate ? (
          <Box sx={{ mt: 4, color: colors.real.medium }}>
            Cancellation effective on {cancellationDate}.{' '}
            <a
              onClick={() => reactivateSubscription({ plan: mainSub.plan })}
              style={{ textDecoration: 'underline', fontWeight: 700, cursor: 'pointer' }}
            >
              Click here to reactivate.
            </a>
          </Box>
        ) : null}

        {parentCancellationDate && getUserQuery.isSuccess && parentSub ? (
          <Box sx={{ mt: 4, color: colors.real.medium }}>
            {planNames.data?.[parentSub.plan]} plan ends on {parentCancellationDate}.<br />
            <Tooltip aria-label={getUserQuery.data!.parentEmail} direction="n">
              <a
                onClick={() => {
                  window.location.href = 'mailto:' + getUserQuery.data!.parentEmail;
                }}
                style={{ textDecoration: 'underline', fontWeight: 700, cursor: 'pointer' }}
              >
                Questions? Contact the account admin.
              </a>
            </Tooltip>
          </Box>
        ) : null}

        <Box sx={{ mt: 4 }}>
          <b>
            Your{' '}
            {`${parentSub ? planNames.data?.[parentSub!.plan] : ''}${
              parentSub && mainSub.plan && mainSub.plan !== 'FREE' ? ' and ' : ''
            }${mainSub.plan == 'FREE' ? '' : planNames.data?.[mainSub.plan]} `}{' '}
            plan includes
          </b>
          :{' '}
        </Box>

        {getConfigs.data && mainSub && getConfigs.data![mainSub.plan] ? (
          <>
            {mainSub.allowChildren ? (
              <Box sx={{ ml: 2 }}>
                - {mainSub.maxNoOfChildren + 1} licenses ({(getKids.data?.children.length ?? 0) + 1}{' '}
                allocated)
              </Box>
            ) : null}
            <Box sx={{ ml: 2 }}>
              -{' '}
              {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0) >
              100
                ? 'Unlimited'
                : getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0)}{' '}
              game room
              {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth > 1 ? 's' : ''} a month{' '}
            </Box>
            <Box sx={{ ml: 2 }}>
              - Open up to{' '}
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfConcurrentRooms,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfConcurrentRooms : 0
              )}{' '}
              room
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfConcurrentRooms,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfConcurrentRooms : 0
              ) > 1
                ? 's'
                : ''}{' '}
              at a time{' '}
            </Box>
            <Box sx={{ ml: 2 }}>
              - Up to{' '}
              {Math.max(
                getConfigs.data![mainSub.plan].MaxNoOfPlayersPerGame,
                parentSub ? getConfigs.data![parentSub.plan].MaxNoOfPlayersPerGame : 0
              )}{' '}
              players in a game room{' '}
            </Box>

            <Box sx={{ mt: 4, mb: '20px' }}>
              <b>
                You've used {mainSub.playedThisMonth + (parentSub ? parentSub.playedThisMonth : 0)}{' '}
                /{' '}
                {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0)}{' '}
                game
                {getConfigs.data![mainSub.plan].MaxNoOfGamesPerMonth +
                  (parentSub ? getConfigs.data![parentSub.plan].MaxNoOfGamesPerMonth : 0) >
                1
                  ? 's'
                  : ''}{' '}
                this month - renews{' '}
                {monthlyDate.toLocaleDateString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
                .
              </b>
            </Box>

            {sub.data!.find(
              (s) =>
                s.isSubscriptionActive && s.isTemporaryAddOnSubscription && s.plan != 'KICKSTARTER'
            ) ? (
              <Box sx={{ mb: '20px', color: colors.spark.btn, width: '100%', display: 'flex' }}>
                <div style={{ margin: 'auto' }}>
                  ✨{' '}
                  <a
                    onClick={() =>
                      (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
                    }
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    <b>Add-on(s) active - see details</b>
                  </a>{' '}
                  ✨
                </div>
              </Box>
            ) : null}
          </>
        ) : null}

        {getUserQuery.data && sub.data && (sub.data.length > 1 || sub.data[0].plan != 'FREE') ? (
          <Box sx={{ mb: '50px', fontSize: '15px' }}>
            {parentSub ? (
              <>
                The admin of this account is {getUserQuery.data!.parentFullname} – you can contact
                them at{' '}
                <a
                  href={'mailto:' + getUserQuery.data!.parentEmail}
                  style={{ color: '#FF7300', fontWeight: 700 }}
                >
                  {getUserQuery.data!.parentEmail}
                </a>
                .{' '}
              </>
            ) : (
              ''
            )}
            {!parentSub ? (
              <>To change your payment method or update your billing information, </>
            ) : (
              <>To update your payment information for add-on purchases, </>
            )}{' '}
            <a
              href={getUserQuery.data?.stripeCustomerPortal}
              style={{ color: '#FF7300', fontWeight: 700 }}
            >
              click here
            </a>
            .
          </Box>
        ) : null}

        <Bar
          style={{
            bottom: mainSub
              ? !sub.data?.find((s) => s.isSubscriptionActive && s.plan == 'KICKSTARTER') &&
                !sub.data?.find((s) => s.plan !== 'FREE' && !s.isTemporaryAddOnSubscription) &&
                !sub.data?.find((s) => s.fromParent)
                ? '165px'
                : '134px'
              : '160px',
          }}
        />
        {sub.data?.find((s) => s.plan == 'FREE') ? (
          <RowBox style={{ gap: '15px' }}>
            <Button
              onClick={() =>
                (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
              }
              size="large"
              sx={{
                mr: 1,
                ...buttonStyle,
                mt: 3,
                mb: 3,
                width: '100%',
                flex: '1 0',
                color: colors.accent.emphasis,
                backgroundColor: colors.real.muted,
                borderColor: colors.accent.emphasis,
              }}
              variant="primary"
            >
              Add-Ons
            </Button>
            <Button
              onClick={() => setPlansOverlayOpen(true)}
              size="large"
              sx={{ mr: 1, ...buttonStyle, mt: 3, mb: 3, width: '100%', flex: '1 0' }}
              variant="primary"
            >
              ✨ See plans ✨
            </Button>
          </RowBox>
        ) : (
          <RowBox style={{ gap: '15px' }}>
            <Button
              onClick={() => setPlansOverlayOpen(true)}
              size="large"
              sx={{
                mr: 1,
                ...buttonStyle,
                mt: 3,
                mb: 3,
                width: '100%',
                flex: '1 0',
                color: colors.accent.emphasis,
                backgroundColor: colors.real.muted,
                borderColor: colors.accent.emphasis,
              }}
              variant="primary"
            >
              See plans
            </Button>
            <Button
              onClick={() =>
                (addOnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
              }
              size="large"
              sx={{ mr: 1, ...buttonStyle, mt: 3, mb: 3, width: '100%', flex: '1 0' }}
              variant="primary"
            >
              ✨ Add-Ons ✨
            </Button>
          </RowBox>
        )}

        {!sub.data?.find((s) => s.isSubscriptionActive && s.plan == 'KICKSTARTER') &&
        !sub.data?.find((s) => s.plan !== 'FREE' && !s.isTemporaryAddOnSubscription) &&
        !sub.data?.find((s) => s.fromParent) ? (
          <a
            onClick={() => checkout({ plan: KICKSTARTER_PLAN })}
            style={{
              color: colors.juicy.medium,
              display: 'block',
              width: '100%',
              textDecoration: 'underline',
              textAlign: 'center',
              padding: '0px 0px 10px 0px',
              cursor: 'pointer',
            }}
          >
            Enter Kickstarter promo code
          </a>
        ) : null}
        <DeleteAccountButton button={false} />
      </Layout.Panel>

      <MainTitle ref={addOnRef} style={{ fontSize: '26px', marginTop: '50px' }}>
        {sub.data?.some((p) => p.isSubscriptionActive && p.isTemporaryAddOnSubscription)
          ? 'Active & '
          : ''}
        Available Add-Ons
      </MainTitle>

      {mainSub.plan !== 'PRO' &&
      mainSub.plan !== 'COMPANY' &&
      (!parentSub || (parentSub && parentSub.plan !== 'PRO' && parentSub.plan !== 'COMPANY')) ? (
        <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
          <AddOnContainer>
            <AddOnTop plan="ONE_TIME_GAMES">One-Time Games</AddOnTop>
            <div style={{ padding: '30px' }}>
              <RowBox>
                <AddOnPrice
                  style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                >
                  $40
                </AddOnPrice>
                <AddOnPriceComplement
                  style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                >
                  /game room
                </AddOnPriceComplement>
              </RowBox>
              <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                Buy game rooms for up to 8 players. Choose any edition. Valid for 24 hours.
              </AddOnDescription>
              <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                Add to your plan
              </AddOnWhatsIncluded>
              <PlanItem>
                Up to <b>8 players</b> in a game room
              </PlanItem>
              <PlanItem>
                Choose from <b>all available editions</b>
              </PlanItem>
              <PlanItem>
                Stack this add-on up to 3 times for <b>events up to 24 players</b>
              </PlanItem>
              <Button
                disabled
                size="large"
                sx={{
                  width: '160px',
                  marginLeft: 'auto',
                  marginTop: '16px',
                  ...buttonStyle,
                  backgroundColor: colors.spark.light,
                  borderColor: colors.spark.other,
                  color: colors.spark.other,
                }}
                type="button"
                variant="primary"
              >
                Coming soon
              </Button>
            </div>
          </AddOnContainer>
        </Container>
      ) : null}

      {mainSub.plan == 'PRO' || parentSub?.plan == 'PRO' ? (
        <>
          <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
            <AddOnContainer ref={allEditionsRef}>
              <AddOnTop plan="ALL_EDITIONS">All Editions</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $10
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /one month
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Increase your toolkit with access to all editions of Cozy Juicy Real. Valid for 1
                  month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  <b>Team Building Level 1</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Team Building Level 2</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Team Building Level 3</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Agile Mindset</b> edition
                </PlanItem>
                <PlanItem>
                  <b>DEI</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Leadership</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Creativity</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Women's</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Spanish Level 1</b> edition
                </PlanItem>
                <PlanItem>
                  <b>German Level 1</b> edition
                </PlanItem>
                <PlanItem>
                  <b>Double Speed</b> edition
                </PlanItem>
                <BuyNowBtn plan="ALL_EDITIONS" />
              </div>
            </AddOnContainer>

            <AddOnContainer ref={largeGroupsRef}>
              <AddOnTop plan="LARGE_GROUPS">Large Groups</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $40
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /one month
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Increase player limit to 12 players in your game rooms. Valid for 1 month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Increase your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  Up to <b>12 players</b> in every game room
                </PlanItem>
                <BuyNowBtn plan="LARGE_GROUPS" />
              </div>
            </AddOnContainer>

            <AddOnContainer>
              <AddOnTop>Large Events</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPriceComplement
                    style={{
                      height: '38px',
                      paddingTop: '16px',
                      paddingLeft: '0px',
                      paddingRight: '10px',
                      color: colors.spark.btn,
                    }}
                  >
                    From
                  </AddOnPriceComplement>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $165
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /5 game rooms
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Add extra game rooms. Buy multiple packs for events over 120 players. Valid for 1
                  month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  Host events up to <b>500 players</b>
                </PlanItem>
                <PlanItem>
                  Add additional <b>game rooms</b>
                </PlanItem>
                <PlanItem>
                  Open up to <b>100 game rooms</b> at once
                </PlanItem>
                <PlanItem>
                  Access to <b>all editions</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk create games rooms</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk copy game room links</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk delete game rooms</b>
                </PlanItem>

                <CJRButton
                  onClick={() =>
                    (largeEventsnRef.current! as HTMLElement).scrollIntoView({ behavior: 'smooth' })
                  }
                  style={{ width: '160px', marginLeft: 'auto', marginTop: '16px' }}
                  variant="sparkSecondary"
                >
                  See below
                </CJRButton>
              </div>
            </AddOnContainer>
          </Container>

          <MainTitle ref={largeEventsnRef} style={{ fontSize: '26px', marginTop: '50px' }}>
            Large Event Packs
          </MainTitle>

          <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
            <AddOnContainer>
              <AddOnTop plan="FIVE_GAME_ROOMS">Five Game Rooms</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $165
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /5 game rooms
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Add 5 extra game rooms to your plan for events up to 35 players. Valid for 1
                  month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  Host events up to <b>35 players</b>
                </PlanItem>
                <PlanItem>
                  Add an extra <b>5 game rooms</b>
                </PlanItem>
                <PlanItem>
                  Manage up to <b>5 rooms</b> at a time
                </PlanItem>
                <PlanItem>
                  Access to <b>all editions</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk create games rooms</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk copy game room links</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk delete game rooms</b>
                </PlanItem>
                <BuyNowBtn plan="FIVE_GAME_ROOMS" />
              </div>
            </AddOnContainer>

            <AddOnContainer>
              <AddOnTop plan="TEN_GAME_ROOMS">Ten Game Rooms</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $260
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /10 game rooms
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Add 10 extra game rooms to your plan for events up to 70 players. Valid for 1
                  month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  Host events up to <b>70 players</b>
                </PlanItem>
                <PlanItem>
                  Add an extra <b>10 game rooms</b>
                </PlanItem>
                <PlanItem>
                  Manage up to <b>10 rooms</b> at a time
                </PlanItem>
                <PlanItem>
                  Access to <b>all editions</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk create games rooms</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk copy game room links</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk delete game rooms</b>
                </PlanItem>
                <BuyNowBtn plan="TEN_GAME_ROOMS" />
              </div>
            </AddOnContainer>

            <AddOnContainer>
              <AddOnTop plan="TWENTY_GAME_ROOMS">Twenty Game Rooms</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $380
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /20 game rooms
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Add 20 extra game rooms to your plan for events up to 140 players. Valid for 1
                  month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to your plan
                </AddOnWhatsIncluded>
                <PlanItem>
                  Host events up to <b>140 players</b>
                </PlanItem>
                <PlanItem>
                  Add an extra <b>20 game rooms</b>
                </PlanItem>
                <PlanItem>
                  Manage up to <b>20 rooms</b> at a time
                </PlanItem>
                <PlanItem>
                  Access to <b>all editions</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk create games rooms</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk copy game room links</b>
                </PlanItem>
                <PlanItem>
                  <b>Bulk delete game rooms</b>
                </PlanItem>
                <BuyNowBtn plan="TWENTY_GAME_ROOMS" />
              </div>
            </AddOnContainer>
          </Container>
        </>
      ) : null}

      {mainSub.plan == 'COMPANY' || parentSub?.plan == 'COMPANY' ? (
        <>
          <Container columnDisplay={width < MOBILE_WIDTH} style={{ gap: '30px' }} transparentBg>
            <AddOnContainer ref={allHandsnRef}>
              <AddOnTop plan="ALL_HANDS_EVENTS">All-Hands Events</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnPrice
                    style={{ fontSize: '38px', lineHeight: '38px', color: colors.spark.btn }}
                  >
                    $300
                  </AddOnPrice>
                  <AddOnPriceComplement
                    style={{ height: '38px', paddingTop: '16px', color: colors.spark.btn }}
                  >
                    /account
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Facilitate large events that require 20+ breakout rooms open at a time. Valid for
                  1 month.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to one user account
                </AddOnWhatsIncluded>
                <PlanItem>
                  Host an event for up to <b>160 players</b>
                </PlanItem>
                <PlanItem>
                  Manage up to <b>20 rooms</b> at a time
                </PlanItem>
                <PlanItem>
                  Stack this Add-On up to 4 times to host events for <b>500 players</b>
                </PlanItem>
                <BuyNowBtn plan="ALL_HANDS_EVENTS" />
              </div>
            </AddOnContainer>

            <AddOnContainer ref={facilitatorRef}>
              <AddOnTop>Fully Facilitated Events</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnSpeechIcon
                    src={speech}
                    style={{
                      fontSize: '26px',
                      lineHeight: '40px',
                      paddingTop: '5px',
                      height: '40px',
                      width: '40px',
                    }}
                  />
                  <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                    Contact us for pricing
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Take off all the pressure of facilitating an event and leave it to us.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  What’s included
                </AddOnWhatsIncluded>
                <PlanItem>
                  <b>Hosted by skilled facilitators</b>
                </PlanItem>
                <PlanItem>
                  <b>20 - 500 players</b> in breakout rooms
                </PlanItem>
                <PlanItem>
                  <b>No preparation necessary</b>
                </PlanItem>
                <Button
                  onClick={() =>
                    window.open(
                      'https://cozyjuicyreal.trafft.com/booking?t=s&s=cozy-juicy-real-demo-20-mins'
                    )
                  }
                  size="large"
                  sx={{
                    width: '160px',
                    marginLeft: 'auto',
                    marginTop: '16px',
                    ...buttonStyle,
                    backgroundColor: colors.spark.light,
                    borderColor: colors.spark.dark,
                    color: colors.spark.dark,
                  }}
                  type="button"
                >
                  Book a call
                </Button>
              </div>
            </AddOnContainer>

            <AddOnContainer ref={customizedGamesRef}>
              <AddOnTop>Custom Editions</AddOnTop>
              <div style={{ padding: '30px' }}>
                <RowBox>
                  <AddOnSpeechIcon
                    src={speech}
                    style={{
                      fontSize: '26px',
                      lineHeight: '40px',
                      paddingTop: '5px',
                      height: '40px',
                      width: '40px',
                    }}
                  />
                  <AddOnPriceComplement style={{ height: '40px', paddingTop: '12px' }}>
                    Contact us for pricing
                  </AddOnPriceComplement>
                </RowBox>
                <AddOnDescription style={{ paddingTop: '8px', minHeight: 'unset' }}>
                  Encompass your company values in a customized edition of Cozy Juicy Real. Added to
                  all accounts.
                </AddOnDescription>
                <AddOnWhatsIncluded style={{ paddingTop: '14px' }}>
                  Add to all user accounts
                </AddOnWhatsIncluded>
                <PlanItem>
                  <b>Custom edition created</b> by the founders of Cozy Juicy Real
                </PlanItem>
                <PlanItem>
                  Questions and game design aligned to <b>your company brand and values</b>
                </PlanItem>
                <PlanItem>
                  Added to <b>all company accounts</b>
                </PlanItem>
                <PlanItem>
                  Ideal for <b>onboarding new team members and team integration</b>
                </PlanItem>
                <Button
                  onClick={() =>
                    window.open(
                      'https://cozyjuicyreal.trafft.com/booking?t=s&s=cozy-juicy-real-demo-20-mins'
                    )
                  }
                  size="large"
                  sx={{
                    width: '160px',
                    marginLeft: 'auto',
                    marginTop: '16px',
                    ...buttonStyle,
                    backgroundColor: colors.spark.light,
                    borderColor: colors.spark.dark,
                    color: colors.spark.dark,
                  }}
                  type="button"
                >
                  Book a call
                </Button>
              </div>
            </AddOnContainer>
          </Container>
        </>
      ) : null}

      <Dialog
        aria-labelledby="label"
        isOpen={plansOverlayOpen}
        onDismiss={() => setPlansOverlayOpen(false)}
        sx={{
          width: '100%',
          maxWidth: width < MOBILE_WIDTH ? '420px' : '1300px',
          maxHeight: 'unset',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          overflowY: 'auto',
          background: 'none',
          position: 'absolute',
          zIndex: 100,
        }}
      >
        <PlansBox />
      </Dialog>
    </Layout>
  );
};

function AddOnTop({ plan, children }: { readonly plan?: string; readonly children?: any }) {
  const subs = useGetSubscriptionStatusQuery(undefined);
  const [sub, setSub] = useState({} as SubscriptionStatus);
  const configs = useListConfigsQuery(undefined);

  useEffect(() => {
    if (subs.isSuccess && plan) {
      setSub(
        subs.data!.find((s) => s.isSubscriptionActive && s.plan == plan) ??
          ({} as SubscriptionStatus)
      );
    }
  }, [subs]);

  return (
    <>
      <AddOnTitle
        style={{
          background: sub.plan ? colors.real.btn : colors.spark.btn,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {children}
        {sub.plan ? (
          <div
            style={{
              width: '122px',
              height: '30px',
              textAlign: 'center',
              borderRadius: '15px',
              background: '#fff',
              color: colors.real.btn,
            }}
          >
            Active
          </div>
        ) : null}
      </AddOnTitle>
      {sub.validTo && configs.data ? (
        <div
          style={{
            padding: '30px 5px 30px 5px',
            textAlign: 'center',
            borderRadius: '15px',
            background: colors.real.bg,
            color: colors.real.btn,
          }}
        >
          Add-on expiring{' '}
          {new Date(sub.validTo! * 1000).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          .{' '}
          {configs.data[sub.plan].MaxNoOfGamesPerMonth > 0 ? (
            <b>
              {sub.playedThisMonth}/{configs.data[sub.plan].MaxNoOfGamesPerMonth} games used.
            </b>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

function BuyNowBtn({ plan }: { readonly plan: string }) {
  const sub = useGetSubscriptionStatusQuery(undefined);
  const { setHref } = useWindowLocation();
  const [hasPlan, setHasPlan] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [checkout, session] = useCheckoutMutation();

  useEffect(() => {
    if (session.isSuccess) setHref(session.data.url);
  }, [session]);

  useEffect(() => {
    if (sub.isSuccess) {
      setHasPlan(!!sub.data!.find((s) => s.isSubscriptionActive && s.plan == plan));
    }
  }, [sub]);

  const onClick = () => {
    if (!hasPlan) {
      if (
        !sub.data!.find(
          (s) => !s.isTemporaryAddOnSubscription && s.isSubscriptionActive && !s.fromParent
        )
      ) {
        checkout({ plan: plan });
      } else {
        setConfirm(true);
      }
    }
  };

  return (
    <>
      <CJRButton
        disabled={hasPlan}
        onClick={onClick}
        style={{
          width: '160px',
          marginLeft: 'auto',
          marginTop: '16px',
        }}
        variant={hasPlan ? 'sparkDisabled' : 'sparkPrimary'}
      >
        {hasPlan ? 'Add-on active' : 'Buy now'}
      </CJRButton>

      {confirm ? (
        <ConfirmAddOnDialog confirm={confirm} plan={plan} setConfirm={setConfirm} />
      ) : null}
    </>
  );
}

import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Dialog, TextInput } from '@primer/react';
import {
  useAddAddOnSubscriptionMutation,
  useGetPriceChangeQuery,
  useGetSubscriptionStatusQuery,
} from '../../redux/apis/payment';
import { stopConfettiInner } from '../confetti/confetti';
import { CJRButton } from '../../basicComponents/cjrButton/cjrButton';
import { setManualLoading, showLoading } from '../../redux/slices/modal.slice';
import { useAppDispatch } from '../../redux/hooks';
import { UnsuccessfulDialog } from './UnsuccessfulDialog';
import { usePlanNamesQuery } from '../../redux/apis/config';
import { colors } from '../../theme/theme';

interface UpgradeConfirmDialogProps {
  readonly plan: string;
  readonly confirm: boolean;
  readonly setConfirm: (value: boolean) => void;
}

export const ConfirmAddOnDialog: FunctionComponent<UpgradeConfirmDialogProps> = ({
  plan,
  confirm,
  setConfirm,
}) => {
  const dispatch = useAppDispatch();
  stopConfettiInner();

  const [allDone, setAllDone] = useState(false);
  const [promoCodeBox, setPromoCodeBox] = useState(false);
  const [cupomCodeTemp, setCupomCodeTemp] = useState<string | undefined>(undefined);
  const [cupomCode, setCupomCode] = useState<string | undefined>(undefined);
  const sub = useGetSubscriptionStatusQuery(undefined);
  const price = useGetPriceChangeQuery({ plan, cupomCode }, { refetchOnMountOrArgChange: true });
  const [addAddOn, addedSub] = useAddAddOnSubscriptionMutation();
  const planNames = usePlanNamesQuery(undefined);

  useEffect(() => {
    setAllDone(false);
  }, []);

  useEffect(() => {
    if (addedSub.isSuccess) setAllDone(true);
  }, [addedSub]);

  const onClick = () => {
    addAddOn({ plan, cupomCode });
    dispatch(setManualLoading(true));
    dispatch(showLoading());
  };

  if (!sub.data || !price.data) return <></>;
  if (addedSub.isError)
    return <UnsuccessfulDialog open={confirm} setOpen={setConfirm} tryAgain={onClick} />;

  return !allDone ? (
    <Dialog
      aria-labelledby="label"
      isOpen={confirm}
      onDismiss={() => setConfirm(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        Confirm your add-on
      </div>
      <Box p={4}>
        <Box p={3}>
          You’re about to get the <b>{`${planNames.data?.[plan]}`}</b> add-on and will be charged{' '}
          <b>${`${price.data!.price}`}</b> to your card
          {`${
            sub.data!.find((p) => !p.isTemporaryAddOnSubscription)!.paymentCard != ''
              ? ' ending with ' +
                sub.data!.find((p) => !p.isTemporaryAddOnSubscription)!.paymentCard
              : ''
          }`}
          .
        </Box>
        <Box p={3}>
          You’ll get access to your add-on features immediately and they’ll remain valid until{' '}
          <b>{`${new Date(
            Date.now() + (price.data!.interval == 'year' ? 365 : 30) * 24 * 60 * 60 * 1000
          ).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`}</b>
          .
        </Box>
        <Box p={3}>
          Click <b>Continue</b> below to confirm...
        </Box>
      </Box>
      <Box
        style={{
          gap: '15px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        {promoCodeBox ? (
          <Box display="flex" flexDirection="row" p={4} pb={0}>
            <TextInput
              maxLength={20}
              onChange={(e) => setCupomCodeTemp(e.target.value)}
              placeholder="Enter promo code"
              sx={{
                height: '40px',
                borderColor: colors.accent.emphasis,
                backgroundColor: price.isError ? '#FFCCCB' : 'white',
                width: '100%',
              }}
            />
            <CJRButton
              onClick={() => {
                setCupomCode(cupomCodeTemp);
              }}
              style={{ width: '160px', position: 'absolute', right: '25px' }}
              variant="juicySecondary"
            >
              Apply
            </CJRButton>
          </Box>
        ) : null}
        <Box display="flex" justifyContent="center" p={4}>
          {!promoCodeBox ? (
            <CJRButton
              onClick={() => setPromoCodeBox(true)}
              style={{ marginRight: '5px' }}
              variant="juicyTertiary"
            >
              Enter promo code
            </CJRButton>
          ) : null}
          <CJRButton
            disabled={price.isError}
            onClick={onClick}
            style={{ marginLeft: '5px', maxWidth: '200px' }}
            variant="juicyPrimary"
          >
            Continue
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  ) : (
    <Dialog
      aria-labelledby="label"
      isOpen={confirm}
      onDismiss={() => setConfirm(false)}
      sx={{ height: 'initial !important' }}
    >
      <div
        style={{
          height: '80px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          padding: '28px',
          display: 'flex',
          justifyContent: 'center',
          color: '#3B147A',
          textAlign: 'center',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        }}
      >
        All done!
      </div>
      <Box p={4}>
        <Box p={3}>
          Okey dokes, your <b>{`${plan[0]}${plan.slice(1).toLowerCase().replace('_', ' ')}`}</b>{' '}
          add-on has been unlocked. 🙌
        </Box>
        <Box p={3}>You’ll receive an email shortly confirming the update.</Box>
        <Box p={3}>
          Enjoy your juicy new features and editions, and as always, feel free to reach out if you
          have any questions at <b>team@cozyjuicyreal.com</b>.
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            gap: '15px',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <CJRButton
            onClick={() => setConfirm(false)}
            style={{ maxWidth: '200px', margin: 'auto' }}
            variant="juicyPrimary"
          >
            Close
          </CJRButton>
        </Box>
      </Box>
    </Dialog>
  );
};

import { FunctionComponent } from 'react';
import './loadingIcon.css';
import { useApisLoaded } from '../../hooks/useApisLoaded';
import styled from 'styled-components';

const LoadingIcon = styled('div')`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
};`;

export const Loading: FunctionComponent = () => {
  const show = useApisLoaded();

  //if (!show) return <></>;

  return (
    <LoadingIcon style={{ display: show ? 'initial' : 'none' }}>
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div className="loader-line" />
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line" />
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line" />
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line" />
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line" />
          </div>
        </div>
      </div>
    </LoadingIcon>
  );
};

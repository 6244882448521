import styled from 'styled-components';

const RowBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const ItemText = styled('div')`
  color: #440d9b;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  text-align: left;
`;

export const PlanItem = ({ children }: any) => {
  return (
    <RowBox>
      <ItemText style={{ color: '#896DFF', paddingRight: '6px' }}>
        <b>✓</b>
      </ItemText>
      <ItemText>{children}</ItemText>
    </RowBox>
  );
};
